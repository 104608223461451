<template>
  <div></div>
</template>

<script>
import _ from "lodash";
import "animate.css";

export default {
  name: "BaseListPage",
  data() {
    return {
      //Current item to work with
      selectedItem: null,

      //Status flag whether dialog is visible
      editDialog: false,

      //Status flag whether loader should be visible
      isLoading: false,
      pageReady: false,

      //List of attributes allowed for ordering
      orderAttributes: [],

      defaultFilters: {},
      multipleFilters: {},
      currentRouteName: this.$route.name,
    };
  },
  computed: {
    totalCount: () => 0,
    offset: () => 0,
    itemsPerPage: () => 0,
    //Get order number of last showed row
    maxItem: function () {
      return Math.min(this.totalCount, this.offset + this.itemsPerPage);
    },
    formattedFilters: function () {
      return {};
    },
  },
  watch: {
    //Reload items after ordering changed
    sort: {
      handler: function () {
        this.fetchItems();
      },
      deep: true,
    },
    //Reload items after new page selected
    //Set new url with selected page and filters
    pagination: {
      handler: function () {
        let query = _.merge({}, this.$route.query);
        if (this.pagination.page > 1) {
          query.page = this.pagination.page;
        } else {
          delete query.page;
        }
        this.$router
          .push({
            name: this.currentRouteName,
            query,
          })
          .catch(() => {});
        this.fetchItems();
      },
      deep: true,
    },
    //Reload items after new filters selected
    //Set new url with selected filters, reset selected page
    filters: {
      handler: function () {
        let query = _.merge({}, this.$route.query);
        for (let filter in this.filters) {
          delete query[filter];
        }
        this.$router
          .push({
            name: this.currentRouteName,
            query: _.merge(query, this.formattedFilters),
          })
          .catch(() => {});
        this.pagination.page = 1;
        this.fetchItems();
      },
      deep: true,
    },
  },
  methods: {
    applyFilters: function () {},
    fetchItems: function () {},
    editItem: function () {},
    closeEditDialog: function () {
      this.selectedItem = null;
      this.editDialog = false;
    },
    applyRouteParams: function () {
      let status = false;
      let vm = this;
      if (!_.isEmpty(vm.$route.query)) {
        let filters = {};
        _.forIn(vm.$route.query, function (value, key) {
          if (_.has(vm.emptyFilters, key)) {
            if (_.indexOf(vm.multipleFilters, key) > -1) {
              let values = [];
              if (!(value instanceof Array)) {
                value = [value];
              }
              value.forEach(function (valueItem) {
                if (valueItem) {
                  values.push(
                    isNaN(valueItem) ? valueItem : parseInt(valueItem)
                  );
                } else {
                  values.push(valueItem);
                }
              });
              filters[key] = values;
            } else {
              if (value) {
                if (
                  key === "tag" ||
                  (key === "name" && vm.$route.name === "TagListPage")
                ) {
                  if (value.indexOf(",") >= 0) {
                    filters[key] = [...new Set(value.split(","))];
                  } else {
                    if (value instanceof Array) {
                      filters[key] = value;
                    } else {
                      filters[key] = [value];
                    }
                  }
                } else {
                  value = isNaN(value) ? value : parseInt(value);
                  filters[key] = value;
                }
              } else {
                filters[key] = value;
              }
            }
          }
        });
        if (vm.$route.query.page) {
          vm.pagination.page = parseInt(vm.$route.query.page);
        }
        if (!_.isEmpty(filters)) {
          const data = _.merge({}, vm.emptyFilters, filters);
          vm.applyFilters({ data });
        }
        status = true;
      }
      this.pageReady = true;
      return status;
    },
    itemClass() {
      return "animate__animated animate__slideInRight";
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.applyFilters({
        data: _.merge({}, vm.defaultFilters),
      });
      vm.pagination.page = vm.$route.query.page || 1;
    });
  },
};
</script>
